import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import Container from "~components/Container"
import SEO from "~components/SEO"
import Hero from "~components/Hero"

import { ReactComponent as ContactIcon } from "~images/svg/id-card-solid.svg"
import { getImage, withArtDirection } from "gatsby-plugin-image"

const ContactCard = () => (
  <div className="tw-h-full tw-shadow tw-rounded-lg tw-p-5 tw-bg-white tw-text-primary">
    <h2 className="tw-text-xl tw-font-medium tw-leading-6 tw-not-italic tw-uppercase">
      <ContactIcon className="tw-inline tw-mr-4 tw-relative tw--top-0.5" />
      Fiche contact
    </h2>

    <hr className="tw-mt-2 tw-border-primary" />

    <p className="tw-leading-5 tw-mb-2.5 tw-mt-4">
      <strong>Max VANDENHEDE</strong>
    </p>
    <p className="tw-leading-5 tw-mb-2.5">
      <strong>
        Téléphone : <a href="tel:0664329598">06 64 32 95 98</a>
      </strong>
    </p>
    <p className="tw-leading-5 tw-mb-2.5">
      <strong>
        Email : <a href="mailto:max.vandenhede@sfr.fr">max.vandenhede@sfr.fr</a>
      </strong>
    </p>
  </div>
)

const QuiSuisJe = ({ data }) => {
  const { bannerImageMobile, bannerImageDesktop, avatarImage } = data

  const images = withArtDirection(getImage(bannerImageMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(bannerImageDesktop),
    },
  ])

  return (
    <Layout>
      <SEO title="Qui suis-je ?" />

      <Hero background={images} />

      <Container>
        <section className="tw-mt-8">
          <h1 className="h1 tw-text-center">Contactez votre guide</h1>
        </section>

        <section className="tw-mt-8">
          <div className="media">
            <img
              src={avatarImage.childImageSharp.fixed.src}
              alt="portrait de Max"
              width="217"
            />

            <div>
              <ContactCard />
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    bannerImageMobile: file(
      relativePath: { eq: "banners/contact-page-banner-mobile.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    bannerImageDesktop: file(
      relativePath: { eq: "banners/contact-page-banner.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    avatarImage: file(relativePath: { eq: "avatar.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 434) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default QuiSuisJe
