import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Hero = ({ background, ...props }) => {
  return (
    <section {...props} className="tw-relative tw-h-80">
      <GatsbyImage
        image={background}
        alt=""
        loading="eager"
        placeholder="none"
        className="tw-absolute tw-h-full tw-w-full"
      />
    </section>
  )
}

export default Hero
